<template>
  <div class="about">
    <div class="a">111</div>
	<div class="b" >222</div>
  </div>
</template>
<style scoped lang="scss" >
	.about{
		display: flex;
		height: 100vh;
		flex-direction:column;
		// justify-content: space-between;
		
	}
	.a{
		flex-shrink:1;
		height: 100%;
		background: red;
	}
	.b{
		flex-shrink:0;
		height: auto;
		background: aqua;
	}
	
</style>
